<style scoped>
  .chapterSonCon{
    /* background: #eee; */
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }
  .chapterSonCon h4{
    padding: 0 0 10px;
    color: #666;
    font-size: 0.8rem;
  }
  .sonList{
    padding-bottom: 20px;
  }
  .sonList li{
    padding: 15px 10px;
    padding-right: 40px;
    line-height: 20px;
    background: rgba(255,255,255,0.5);
    border-radius: 5px;
    margin-bottom: 10px;
    position: relative;
  }
  .sonList li span{
    height: 20px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .sonList li.on span{
    color: #4e43e1;
  }
  .sonList li i{
    position: absolute;
    top: 15px;
    right: 10px;
    width: 20px;
    height: 20px;
    background: url("../../assets/image/icon_slt00.png") no-repeat;
    background-size: 100% 100%;
  }
  .sonList li.on i{
    background: url("../../assets/image/icon_slt.png") no-repeat;
    background-size: 100% 100%;
  }
  .pagebgimg{
      position: fixed;
      top: 0;
      z-index: 0;
      width: 100%;height: 100%;
    }
    .pagebgimg>img{
      width: 100%;height: 100%;
    }
</style>
<template>
  <div id="chapterSon">
    <header-title titleCon="选择子剧情" titleBtnStr="完成" @titleLeftBtn="titleFnReturn" @titleFnBtn="titleFnListener"></header-title>
    <div class="content chapterSonCon" id="chapterSonCon">
      <h4>选择子剧情</h4>
      <ul class="sonList">
        <li v-for="(item, index) in chapterSonList" :class="sltSon === index ? 'on': ''" :key="index">
          <span>{{item.chapterTitle}}</span>
          <i class="icon-slt" @click="changeSon(index)"></i>
        </li>
      </ul>
    </div>
    <div class="pagebgimg">
          <img :src="pagebg" alt="">
    </div>
  </div>
</template>
<script>
import headerTitle from '../../components/header/header-title-transparent.vue'
export default {
  components: {
    headerTitle
  },
  data () {
    return {
      windowHeight: document.documentElement.clientHeight,
      pagebg: require('../../assets/image/pagebgsm.png'),
      sltSon: -1,
      chapterSonList: []
    }
  },
  computed: {
  },
  created () {
    // 适配背景图片
    if (this.windowHeight > 736) {
      this.pagebg = require('../../assets/image/pagebgbig.png')
    }
  },
  mounted () {
    document.getElementById('chapterSonCon').style.height = (window.screen.height - 46) + 'px'
    this.getChapterSonList()
  },
  methods: {
    titleFnReturn () {
      this.$router.go(-1)
    },
    getChapterSonList () {
      this.axios({
        method: 'get',
        url: '/v1/ugcChapterList',
        params: {
          ugcBookId: this.$route.query.bookId,
          chapterType: 1
        }
      }).then((res) => {
        // console.log(res.data.content)
        if (res.data.head.flag === 0) {
          this.chapterSonList = res.data.content.chapterList
        } else {
          this.$toast(res.data.head.desc)
        }
      })
    },
    // 封面选择
    changeSon (index) {
      if (this.sltSon === -1) {
        this.sltSon = index
      } else {
        this.sltSon = -1
      }
    },
    // 选择封面完成
    titleFnListener () {
      if (this.sltSon !== -1) {
        this.$store.state.chapterSonEdit.push({
          subIndex: this.$route.query.chapterSon,
          ugcSubChapter: {
            subChapterId: this.chapterSonList[this.sltSon].id,
            subChapterName: this.chapterSonList[this.sltSon].chapterTitle
          }
        })
      }
      this.$router.go(-1)
      // this.$router.push({ path: '/chapterAdd', query: { bookId: this.$route.query.bookId, chapterId: this.$route.query.chapterId, chapterType: this.$route.query.chapterType, type: this.$route.query.type } })
    }
  }
}
</script>
